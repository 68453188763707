import RootLayout from '../layouts/RootLayout';
import './side.css'

const MainLoader = () => {
    return (
        <RootLayout>
            <div className="loader3 h-[80vh]">
                <div className="bars bar1" />
                <div className="bars bar2" />
                <div className="bars bar3" />
                <div className="bars bar4" />
                <div className="bars bar5" />
                <div className="bars bar6" />
                <div className="bars bar7" />
                <div className="bars bar8" />
                <div className="bars bar9" />
                <div className="bars bar10" />
            </div>
        </RootLayout>
    );
};

export default MainLoader;
