import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const FinishInput = ({ setState, state, name, pl, allowedLocations }) => {
  const [address, setAddress] = useState("");
  const [t] = useTranslation("global");
  console.log(allowedLocations)

  // allowedLocations dan faqat bitta koordinata oling
  const location = allowedLocations; // [lat, lng]

  // Agar location mavjud bo'lsa, LatLngBounds yarating
  const bounds =
    location &&
    new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(location[0] - 0.1, location[1] - 0.1), // Bir oz pasaytirilgan koordinatalar
      new window.google.maps.LatLng(location[0] + 0.1, location[1] + 0.1) // Bir oz ko'tarilgan koordinatalar
    );

  // Koordinatalar chegaralar ichida bo'lganligini tekshiradigan funksiya
  const isWithinBounds = (lat, lng) => {
    return bounds && bounds.contains(new window.google.maps.LatLng(lat, lng));
  };

  const handleChange = (newAddress) => {
    setAddress(newAddress);
    setState({ ...state, [name]: newAddress });
  };

  const handleSelect = (newAddress) => {
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const { lat, lng } = latLng;

        // Chegaralar ichida bo'lsa, tanlang
        if (isWithinBounds(lat, lng)) {
          setAddress(newAddress);
          setState({ ...state, [name]: newAddress });
          console.log("Tanlangan joy:", latLng);
        } else {
          console.warn("Tanlangan joy belgilangan chegaralar ichida emas");
        }
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        bounds, // Belgilangan joy atrofidagi qidiruv chegaralari
        strictBounds: true, // Faqat chegaralar ichidagi natijalarni qidirish
        types: ["address"],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative">
          <input
            {...getInputProps({
              placeholder: pl,
              className:
                "location-search-input border w-[100%] py-2 rounded-md text-[#667085] text-[14px]  focus:outline-[#1348F9] px-3 font-medium",
            })}
            value={address}
            required
          />
          {suggestions && (
            <div
              className={`autocomplete-dropdown-container ${
                suggestions[0]?.description
                  ? "p-2 mt-3 z-[9999] gap-y-1 border-[#1348F9] border-2 rounded-lg"
                  : ""
              } `}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span
                      className="text-[#1348F9] font-semibold"
                      onClick={() => handleSelect(suggestion.description)}
                    >
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default FinishInput;



























// import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";

// const FinishInput = ({ setState, state, name, pl, allowedLocations }) => {
//   const [address, setAddress] = useState("");
//   const [t] = useTranslation("global");

//   // allowedLocations dan a1 va a2 koordinatalarini oling
//   const a1 = allowedLocations?.a1; // Buxoro koordinatalari
//   const a2 = allowedLocations?.a2; // Samarqand koordinatalari

//   // Chegarani aniqlash uchun LatLngBounds o'rnatish
//   const bounds = (a1 && a2) && new window.google.maps.LatLngBounds(
//     new window.google.maps.LatLng(Math.min(a1[0], a2[0]), Math.min(a1[1], a2[1])),
//     new window.google.maps.LatLng(Math.max(a1[0], a2[0]), Math.max(a1[1], a2[1]))
//   );

//   // Belgilangan koordinatalar chegaralar ichida bo'lganligini tekshiradigan funksiya
//   const isWithinBounds = (lat, lng) => {
//     return bounds.contains(new window.google.maps.LatLng(lat, lng));
//   };

//   const handleChange = (newAddress) => {
//     setAddress(newAddress);
//     setState({ ...state, [name]: newAddress });
//   };

//   const handleSelect = (newAddress) => {
//     geocodeByAddress(newAddress)
//       .then((results) => getLatLng(results[0]))
//       .then((latLng) => {
//         const { lat, lng } = latLng;

//         // Chegaralar ichida bo'lsa, tanlang
//         if (isWithinBounds(lat, lng)) {
//           setAddress(newAddress);
//           setState({ ...state, [name]: newAddress });
//           console.log("Tanlangan joy:", latLng);
//         } else {
//           // Agar joy belgilangan chegaralar ichida bo'lmasa, ogohlantirish
//           console.warn("Tanlangan joy belgilangan chegaralar ichida emas");
//         }
//       })
//       .catch((error) => console.error("Error", error));
//   };

//   return (
//     <PlacesAutocomplete
//       value={address}
//       onChange={handleChange}
//       onSelect={handleSelect}
//       searchOptions={{
//         bounds, // Buxoro va Samarqand o'rtasidagi chegaralarni o'rnatish
//         strictBounds: true, // Faqat chegara ichidagi natijalarni qidirish
//         types: ["address"],
//       }}
//     >
//       {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//         <div className="relative">
//           <input
//             {...getInputProps({
//               placeholder: pl,
//               className:
//                 "location-search-input border w-[100%] py-2 rounded-md text-[#667085] text-[14px]  focus:outline-[#1348F9] px-3 font-medium",
//             })}
//             value={address}
//             required
//           />
//           {suggestions && (
//             <div
//               className={`autocomplete-dropdown-container ${
//                 suggestions[0]?.description
//                   ? "p-2 mt-3 z-[9999] gap-y-1 border-[#1348F9] border-2 rounded-lg"
//                   : ""
//               } `}
//             >
//               {loading && <div>Loading...</div>}
//               {suggestions
//                 // Natijalarni koordinatalar chegaralari bo'yicha filtrlash
//                 .filter((suggestion) => {
//                   const location = suggestion.placeId
//                     ? suggestion.placeId
//                     : null;
                  
//                   // Har bir suggestion uchun koordinatalarni tekshirish
//                   if (location) {
//                     geocodeByAddress(suggestion.description)
//                       .then((results) => getLatLng(results[0]))
//                       .then((latLng) => {
//                         const { lat, lng } = latLng;
//                         return isWithinBounds(lat, lng);
//                       })
//                       .catch((error) => {
//                         console.error("Error fetching suggestion location:", error);
//                         return false;
//                       });
//                   }

//                   return true; // Agar placeId yo'q bo'lsa, qaytarish
//                 })
//                 .map((suggestion) => {
//                   const className = suggestion.active
//                     ? "suggestion-item--active"
//                     : "suggestion-item";
//                   const style = suggestion.active
//                     ? { backgroundColor: "#fafafa", cursor: "pointer" }
//                     : { backgroundColor: "#ffffff", cursor: "pointer" };
//                   return (
//                     <div
//                       {...getSuggestionItemProps(suggestion, {
//                         className,
//                         style,
//                       })}
//                     >
//                       <span
//                         className="text-[#1348F9] font-semibold"
//                         onClick={() => {
//                           handleSelect(suggestion.description);
//                         }}
//                       >
//                         {suggestion.description}
//                       </span>
//                     </div>
//                   );
//                 })}
//             </div>
//           )}
//         </div>
//       )}
//     </PlacesAutocomplete>
//   );
// };

// export default FinishInput;
