import RootLayout from "../../components/layouts/RootLayout";
import { useTranslation } from "react-i18next";

const Politics = () => {
  const [t] = useTranslation("global");
  return (
    <RootLayout title="Отзывы">
      <div className="w-[90%] mx-auto">
        <p className="text-center  mt-4 md:mt-8 font-semibold md:font-bold text-[18px] mb-4 sm:mb-0 sm:text-[24px] md:text-[28px] lg:text-[55px] text-[#2F2E40]">
          {t(`pol.title`)}
        </p>
      </div>

      <div className="sm:pt-12 md:w-[90%] w-[96%] mx-auto flex flex-col gap-y-6 pb-12">
        <p className="sm:text-start text-center font-semibold text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p1`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p2`)}
        </p>

        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p3`)}
        </p>

        <p className="sm:text-start text-center font-semibold text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p4`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p5`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p6`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p8`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p9`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p10`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p11`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p12`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p13`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p14`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p15`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p16`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p17`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p18`)}
        </p>
        <p className="sm:text-start font-semibold text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p19`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p20`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p21`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p22`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p23`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p24`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p25`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p26`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p27`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p28`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p29`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p30`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p31`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p32`)}
        </p>
        <p className="sm:text-start font-semibold text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p33`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p34`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p35`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p36`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p37`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p38`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p39`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p40`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p41`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p42`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p43`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p44`)}
        </p>
        <p className="sm:text-start text-center font-semibold text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p45`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p46`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p47`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p48`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p49`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p50`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p51`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p52`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p53`)}
        </p>
        <p className="sm:text-start text-center text-sm sm:text-[17px] leading-7 md:text-[21px] text-[#2F2E40CC]/80">
          {t(`pol.p54`)}
        </p>
        
      </div>

      <div className="relative z-0">
        <div className="absolute bottom-0 left-0 h-[50px] w-[30px] sm:w-[50px] md:w-[70px] lg:w-[70px]">
          <img src={"/minLeft.svg"} width={100} height={100} alt={"minLeft"} />
        </div>
        <div className="absolute bottom-0 right-0 h-[50px] w-[30px] sm:w-[50px] md:w-[70px] lg:w-[70px]">
          <img src={"/minRight.svg"} width={100} height={100} alt={"minLeft"} />
        </div>
      </div>
    </RootLayout>
  );
};

export default Politics;
